<template>
  <section class="l-content">
    <el-card class="l-condition" name="hTable">
      <el-form :inline="true" :model="condition">
        <el-form-item label="所属企业" v-if="userInfo.eGroupCode">
          <l-select v-model="condition.enterpriseCode" :data="userInfo.eGroupChildren" :prop="['enterpriseCode', 'enterpriseName']" @change="getData"></l-select>
        </el-form-item>
        <el-form-item>
          <template slot="label">
            <l-select v-model="condition.dateType" :all="false" :data="$constant.advice_dateType"></l-select>
          </template>
          <l-datepicker type="daterange" :default="[condition.startDate, condition.endDate]" @change="dataRangeChange"></l-datepicker>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" size="small" @click="getData()">查询</el-button>
        </el-form-item>
      </el-form>
    </el-card>
    <el-divider> </el-divider>

    <el-card v-if="pageData">
      <!-- <l-table :option="tableOption" :data="pageData">

      </l-table> -->
      <el-table
    :data="pageData"
    border
    show-summary
    :height=this.$lgh.stripeTableHeight(0)
    style="width: 100%">
    <el-table-column
      type="index"
      label="序号"
      align='center'
      width="90">
    </el-table-column>
    <el-table-column
      prop="deparmentName"
      sortable
      label="部门名称">
    </el-table-column>
    <el-table-column
      prop="userCount"
      sortable
      width="100"
      align='right'
      label="在岗人数">
    </el-table-column>
    <el-table-column
      prop="submitCount"
      sortable
      width="100"
      align='right'
      label="提交条数">
    </el-table-column>
    <el-table-column
      prop="submitOkCount"
      sortable
      width="120"
      align='right'
      label="提交有效条数">
    </el-table-column>
    <el-table-column
      prop="selectCount"
      sortable
      width="100"
      align='right'
      label="采纳条数">
    </el-table-column>
    <el-table-column
      prop="effectCount"
      sortable
      width="120"
      align='right'
      label="实施中条数">
    </el-table-column>
    <el-table-column
      prop="effectOkCount"
      sortable
      width="120"
      align='right'
      label="实施完成条数">
    </el-table-column>
  </el-table>
    </el-card>
  </section>
</template>

<script>
import { mapState } from 'vuex'
export default {
  computed: {
    ...mapState(['userInfo'])
  },
  name: 'AdviceList',
  data() {
    return {
      tableOption: {
        stripe: true,
        columns: [
          { label: '序号', type: 'index' },
          { label: '部门名称', prop: 'deparmentName', overflow: true, sortable: true },
          { label: '部门人数', prop: 'userCount', sortable: true },
          { label: '提交条数', prop: 'submitCount', sortable: true },
          { label: '提交有效条数', prop: 'submitOkCount', sortable: true },
          { label: '入围条数', prop: 'selectCount', sortable: true },
          { label: '实施中条数', prop: 'effectCount', sortable: true },
          { label: '实施完成条数', prop: 'effectOkCount', sortable: true }
        ]
      },
      condition: {
        departId: '',
        dateType: 'submitTime',
        startDate: '',
        endDate: ''
      },
      tableData: [{
        id: '12987122',
        name: '王小虎',
        amount1: '234',
        amount2: '3.2',
        amount3: 10
      }, {
        id: '12987123',
        name: '王小虎',
        amount1: '165',
        amount2: '4.43',
        amount3: 12
      }, {
        id: '12987124',
        name: '王小虎',
        amount1: '324',
        amount2: '1.9',
        amount3: 9
      }, {
        id: '12987125',
        name: '王小虎',
        amount1: '621',
        amount2: '2.2',
        amount3: 17
      }, {
        id: '12987126',
        name: '王小虎',
        amount1: '539',
        amount2: '4.1',
        amount3: 15
      }],
      pageData: null
    }
  },
  created() {
    this.condition.enterpriseCode = this.userInfo.enterpriseCode
    this.condition.eGroupCode = this.userInfo.eGroupCode
    this.getData()
  },
  methods: {
    dataRangeChange(d1, d2) {
      this.condition.startDate = d1
      this.condition.endDate = d2
    },
    async getData(pageOption) {
      Object.assign(this.condition, pageOption)
      this.pageData = await this.$lgh.get('api/enterprise/advice/department/count', this.condition)
    },
    getSummaries(param) {
      const { columns, data } = param
      const sums = []
      columns.forEach((column, index) => {
        if (index === 0) {
          sums[index] = '总价'
          return
        }
        const values = data.map(item => Number(item[column.property]))
        if (!values.every(value => isNaN(value))) {
          sums[index] = values.reduce((prev, curr) => {
            const value = Number(curr)
            if (!isNaN(value)) {
              return prev + curr
            } else {
              return prev
            }
          }, 0)
          sums[index] += ' 元'
        } else {
          sums[index] = 'N/A'
        }
      })

      return sums
    }
  }
}
</script>
